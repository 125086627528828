import { Box, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
// import Image from "../herosectionimg_0.png"
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/700.css';
// const styles = {
//     paperContainer: {
//         height:650,
//         width:"100%",
//         backgroundImage: `url(${Image})`,
//         backgroundPosition: "center",
        // backgroundSize: "cover",
//         // backgroundRepeat: "no-repeat",
//     }
// };
const HeroSection = () => {
  return (
    // <Paper style={styles.paperContainer}>
         <Grid container display={"flex"} direction={"column"}>
          <img width={"100%"} src='/assets/herosectionimg_0.png' alt='hero section img' />
        {/* <Typography variant='h7' color={'#23418D'} >There are multiple ways to win a match!</Typography>
        <Typography variant='h5' color={'#23418D'}>But there is only one way to</Typography>
        <Typography variant='h5' color={"#0ddbab"}>segregate your waste!</Typography> */}
        </Grid>

        // <img style={{marginTop:45}} width={350} height={200} src='/assets/bin.png' alt='bin'/> 
        
    // </Paper>
  )
}

export default HeroSection