import { Grid, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import Square from './Square'
import { Pattern } from './winningPattern'
import ScratchCardPage from './ScratchCard'

const TicTacToe = ({setplay}) => {
    const [board, setBoard] = useState(["","","","","","","","",""]);
    const [player, setPlayer] = useState("O");
    const [ result, setResult] =useState({winner: "none", state: "none" });
    const [openCard, setOpenCard] = useState(false);
    const [wrongSqr, setWrongSqr] = useState(false)
    // useEffect(()=>{
    //     console.log(wrongSqr)
    // },[wrongSqr])
     useEffect(()=>{
        checkWin();
        checktie();
        if(!wrongSqr){

            if(player ==="O"){
                setPlayer("X")
            } else{
                setPlayer("O")
        }
    }
    setWrongSqr(false)
    },[board]);
    useEffect(()=>{
        if(result.state !== "none"){

            alert(`player ${result.winner} has Won!!`);
            restartGame();
        }
    },[result])
    const chooseSquare = (sqr) =>{
        if(board[sqr] !== ""){
            setWrongSqr(true)
        }
        setBoard(board.map((val, index )=>{
            if(index === sqr && val === ""){
                return player
            }
            else return val;
            
            
        }))

  
    };

    const checkWin = ()=>{
        Pattern.forEach((currPattern)=>{
            const firstPlayer = board[currPattern[0]];
            if(firstPlayer === "") return;
            let foundWinningPattern = true;
            currPattern.forEach((idx)=>{
                if(board[idx] !== firstPlayer){
                    foundWinningPattern = false
                }
            });
            if(foundWinningPattern){
                setResult({winner: player, state : "WON"})
                setOpenCard(true);
            }
        })
    }

    const checktie =() =>{
        // console.log(board);
        let filled = true;
        board.forEach((sqr) =>{
            if(sqr === ""){
                filled = false
            }
        });
        if(filled){
            setResult({winner: "No One", state: "Tie"})
        }
    };
    const restartGame =()=>{
        setBoard(["","","","","","","","",""])
    }
  return (
    <>
    {
        !openCard &&
    <Grid container marginBlock={2} marginBottom={6}  height={400}>
        <Typography variant='p' color={'gray'} margin={2}>Note : This is two player game.</Typography>
        <Grid container display={'flex'} direction={'row'} height={"33%"}>
       <Square val={board[0]} chooseSqure={()=>chooseSquare(0)}/>
       <Square val={board[1]} chooseSqure={()=>chooseSquare(1)}/>
       <Square val={board[2]} chooseSqure={()=>chooseSquare(2)}/>
        </Grid>
        <Grid container display={'flex'} direction={'row'}  height={"33%"}>
       <Square val={board[3]} chooseSqure={()=>chooseSquare(3)}/>
       <Square val={board[4]} chooseSqure={()=>chooseSquare(4)}/>
       <Square val={board[5]} chooseSqure={()=>chooseSquare(5)}/>
        </Grid>
        <Grid container display={'flex'} direction={'row'}  height={"33%"}>
       <Square val={board[6]} chooseSqure={()=>chooseSquare(6)}/>
       <Square val={board[7]} chooseSqure={()=>chooseSquare(7)}/>
       <Square val={board[8]} chooseSqure={()=>chooseSquare(8)}/>
        </Grid>
    </Grid>
    }
    {openCard && 
    
    <ScratchCardPage/>
    }
    </>
  )
}

export default TicTacToe

// const Square = (val)=>{
//     return(

//     <Grid container display={'flex'} sx={{placeItems:"center"}} bgcolor={"yellow"} flex={"33%"} height={"100%"}  border={"solid black"}>
//        <Typography alignSelf={"center"}>{val}</Typography>
//     </Grid>
//     )
// }