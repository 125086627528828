import { Box, Typography } from '@mui/material'
import React from 'react'

const Header = () => {
  return (
    <Box display={'flex'} flexDirection={'row'}>
        <img width={300} height={80} src='/assets/header_01.png' alt='recity Logo'/>   
        {/* <Typography variant="h4" color={'#23418D'} marginLeft={1}>@</Typography>
        <img width={50} height={50} src='/assets/ekanaLogo.png' alt='ekana logo'/>
        <img width={100} height={50} src='/assets/ekana_sportz_city_02.png' alt='ekana cricket stadium' /> */}
    </Box>
  )
}

export default Header