import { Box, Button, Grid, Link, Typography } from '@mui/material'
import React from 'react'


const AboutRecity = () => {
  return (
    <Grid container paddingTop={2} width={"100%"} height={330} justify="center" alignContent={'center'} alignItems={"center"} direction={"column"}>
        <Box display={'flex'} flexDirection={"row"} >

        <Typography color={"gray"} paddingInline={2} variant='h5'>About
        </Typography>
        <img width={80} height={30} src='/assets/recity_name.png' alt='recity Logo'/>   
        </Box>
        <Typography color={"gray"} padding={1} paddingBottom={4} variant='p'>
            Recity creates a tech-enabled circular economy of plastics globally and is
            innovating solutions to maximize the circularity of all kinds of plastics. By
            forging efficient public private partnerships and enabling infrastructure for
            the waste ecosystem we are reducing carbon footprints and generating
            plastic credits.
          </Typography>
     
        <Typography marginBottom={2} color={"gray"} variant='h5'>To visit our website</Typography>
        <Button  href='https://www.recity.in'  variant="contained">Click here</Button>
    </Grid>
  )
}

export default AboutRecity

