import { Box, Button, Grid, Typography } from '@mui/material'
import React from 'react'
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

const Social = () => {
  return (
    <Grid container width={"100%"} marginBottom={2}  justify="center" alignContent={'center'} alignItems={"center"} direction={"column"}>
        <Typography marginInline={4}  color={"gray"} variant='h6'>Follow us on our social media</Typography>
    <Box marginInline={4} display={"flex"} flexDirection={"row"} justify={"space-between"} >
        <Button href='https://www.linkedin.com/company/recityindia'>
        <LinkedInIcon fontSize='large'/>
        </Button>
        <Button href='https://www.instagram.com/recitynetworkindia/'>

        <InstagramIcon color='E4405F' fontSize='large'/>
        </Button>
        <Button href='https://www.facebook.com/RecityIndia/'>

        <FacebookIcon fontSize='large'/>
        </Button>
        <Button href='https://twitter.com/RecityIndia'>

        <TwitterIcon fontSize='large'/>
        </Button>
    </Box>
</Grid>
  )
}

export default Social