import { Button, Grid, Typography } from '@mui/material'
import React from 'react'

const Square = ({val, chooseSqure}) => {
    return(

        <Grid container  justify="center" alignItems="center" direction="column"  flex={"33%"} height={"100%"}  border={"1px solid "} onClick={chooseSqure}>
          <Button fullWidth >

           <Typography variant='h1'  alignSelf={"center"}>{val}</Typography>
          </Button>
        </Grid>
        )
}

export default Square