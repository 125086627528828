import { Box, Checkbox, Divider, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import Odometer from 'react-odometerjs'
import usePledgeData from './data-fetch/pledge-data-fetch';
import 'odometer/themes/odometer-theme-default.css';
const Details = () => {
  const [pledgeCount, setPledgeCount] = useState(0);
  const {takePledge} = usePledgeData(setPledgeCount);
  const [disable, setDisable ] = useState(false);
  const handleChanged = (e) =>{
    takePledge();
    setPledgeCount(pledgeCount + 1)
    setDisable(true)
    // console.log(e.target.checked);
  }
  return (
    <Grid container justify="center" alignItems="center" direction="column">

    <Grid container paddingTop={2} width={"100%"} height={300} bgcolor={"#152d56"} justify="center" alignContent={'center'} alignItems={"center"} direction={"column"}>
        <Typography color={"white"} variant='h1'>
        <Odometer value={2500} duration={3000} />
          </Typography>
        <Typography color={"#3dbdc3"} variant='h3'>Kilograms</Typography>
        <Typography marginInline={8} color={"white"} variant='h5'>waste generated in </Typography>
        <Typography marginInline={8} color={"white"} variant='h5'>
        Ekana Stadium today</Typography>
    </Grid>
    <Grid container paddingTop={2} width={"100%"} height={400} bgcolor={"#193a68"} justify="center" alignContent={'center'} alignItems={"center"} direction={"column"}>
        <Typography color={"white"} variant='h1'>{pledgeCount}</Typography>
        <Typography color={"#3dbdc3"} marginBottom={2} variant='h3'>People</Typography>
        <Typography marginInline={6} color={"white"} variant='h5'>have pledged to dispose  </Typography>
        <Typography marginInline={6} color={"white"} variant='h5' >
        their waste in right bins</Typography>
        
        <Box display={"flex"} marginBlock={2} flexDirection={"row"}>
        <Checkbox  size='large' 
         onChange={e => {
          handleChanged(e) 
        }}
        disabled={disable}
        style ={{
                      color: "#00e676",
                    }}
                    
                    />
        <Typography variant='h5' paddingTop={1} color={"white"}>I pledge to dispose my waste in the right bin</Typography>
        </Box>

    </Grid>
    <Grid container width={"100%"} height={105} bgcolor={"#254d7c"} justify="center" alignContent={'center'} alignItems={"center"} direction={"column"}>
        {/* <Typography color={"white"} marginInline={5} variant='h5'>With your help, we have sent
        
        <span style={{color:"#3dbdc3", fontWeight:"bold"}}> 500 Kgs</span>  of plastic waste 
        
         for recycling, creating a positive environmental  
        
        impact in Lucknow.
        </Typography> */}
        <img width={"100%"} src='/assets/Statement_CWC_01.png' alt='statement img' />
        
    </Grid>
    </Grid>
  )
}

export default Details