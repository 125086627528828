import { Button, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import TicTacToe from '../Game/TicTacToe'
import ScratchCardPage from '../Game/ScratchCard'

const GamePage = () => {
    const [play, setplay] = useState(false)
  return (
    <>
    <Grid container justify="center" alignItems="center" direction="column">
         <Grid container paddingTop={2} width={"100%"} height={180}  justify="center" alignContent={'center'} alignItems={"center"} direction={"column"}>
        <Typography color={'#23418D'} padding={1} variant='h7'>If you dumped your waste in the right bin at the</Typography>
        <Typography fontWeight={"bolder"} color={'#23418D'} variant='h4'>
         <span style={{color:'#59bfd7'}}>Segregation </span>Stations,
        </Typography>
        <Typography color={'#23418D'} paddingTop={1} paddingInline={1} variant='h6'>Play this game and stand a chance to  </Typography>
        <Typography color={'#23418D'} variant='h6'>
        win from the scratch cards!</Typography>
        
    </Grid>
    <Button size='large' variant='contained' onClick={()=> setplay(!play)}>{!play ? "Play Tic Tac Toe" : "Play again"}</Button>
    {play &&  
    <TicTacToe setplay={setplay}/>
    }
    </Grid>
    {/* <ScratchCard /> */}
    </>
    
  )
}

export default GamePage