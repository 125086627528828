import { Box, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import ScratchCard from "react-scratchcard";

const ScratchCardPage = () => {
  const [scratched, setScratyched] =useState(false);
  const settings = {
    paddingTop:50,
      width: 300,
      height: 180,
      image: '/assets/scratchCard_0.png',
      finishPercent: 50,
      onComplete: () => {
        setScratyched(true)
        // setplay(false)
      }
    };
  return (
    <Grid container justify="center" alignItems="center" direction="column">
         <Grid container padding={2} paddingBottom={0} width={"100%"} height={200}  justify="center" alignContent={'center'} alignItems={"center"} direction={"column"}>
        <Typography color={'#59bfd7'} padding={1} variant='h3'>Congratulations!</Typography>
        <Typography fontWeight={"bolder"} color={'blue'} variant='h4'>
         You won a scratch card!
        </Typography>
    </Grid>
        <Typography marginInline={2} color={'#59bfd7'} variant='p'>
          (Touch and hold for few seconds to scratch the card)
      </Typography>
    <ScratchCard {...settings} >
      <Typography color={'#59bfd7'} margin={0} marginBottom={0} variant='h5'>
      { scratched ? <img style={{marginTop:30}} src='/assets/betterLuck_0.png' alt='logo' /> :
        <img style={{marginTop:30, marginLeft:"40px"}} src='/assets/betterLuck_1.png' alt='logo' />
      } 

      </Typography>
      {/* {scratched && 
        <Typography marginLeft={5} color={'#59bfd7'}  variant='h6'>
        But there is no next time <br />
        to save your environment
          </Typography>
        
      } */}
        
  </ScratchCard>
    {/* <Box height={200} width={200} borderRadius={5} bgcolor={"#154c79"} padding={2} >
        <Typography variant='h3' color={'#beeaec'}>SCRATCH HERE</Typography>
    </Box> */}
    </Grid>
  )
}

export default ScratchCardPage